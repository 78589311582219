import { useCallback, useRef, useState } from 'react';
import { useAtomValue } from 'jotai';

import { useAtomicStateStateAtom } from 'src/atoms/atomic-state';
import { geographyComponentStateAtom, geographySearchAtom } from '../atoms';

import { useClickOutside } from 'src/hooks/useClickOutside';
import { useComponentControls } from '../hooks/useComponentControls';
import { useGetPlaceAutocompletePlaceholder } from '../hooks/useGetPlaceAutocompletePlaceholder';

import {
  isDistanceSearchGeography,
  isPlaceSearchGeography,
} from 'src/libs/geography';

import { isValidPlaceChecklistSelection } from '../utils/entity';

import { GeographyTag } from '../GeographyTag';
import { PlaceAutocomplete } from '../PlaceAutocomplete';
import { PlaceCheckList } from '../PlaceCheckList';
import { SearchOptions } from '../SearchOptions';

import css from './styles.module.scss';

interface DesktopGeographySearchProps {
  error: boolean;
}

export function DesktopGeographySearch({
  error = false,
}: DesktopGeographySearchProps) {
  const { closeSearchOptions, openSearchOptions, openSearchModal } =
    useComponentControls();
  const placeAutocompleteRef = useRef<HTMLDivElement>(null);
  const { searchOptionsOpen } = useAtomValue(geographyComponentStateAtom);
  const [geographyData, setGeographyData] =
    useAtomicStateStateAtom(geographySearchAtom);
  const [isPlaceCheckListOpen, setIsPlaceCheckListOpen] = useState(false);
  const getPlaceAutocompletePlaceholder =
    useGetPlaceAutocompletePlaceholder(isPlaceCheckListOpen);

  const handleGeographyTagClick = useCallback(() => {
    if (!geographyData) return;

    closeSearchOptions();

    if (
      isPlaceSearchGeography(geographyData) &&
      isValidPlaceChecklistSelection(geographyData)
    ) {
      setIsPlaceCheckListOpen(true);

      return;
    }

    if (isDistanceSearchGeography(geographyData)) {
      openSearchModal('DISTANCE');

      return;
    }

    openSearchModal('AREA');
  }, [geographyData, closeSearchOptions, openSearchModal]);

  useClickOutside(placeAutocompleteRef, closeSearchOptions, searchOptionsOpen);

  // This useCallback is needed since this function is a dependency of a
  // useEffect in usePlaceAutocomplete hook.
  const handleValidPlaceChecklistSelection = useCallback(
    () => setIsPlaceCheckListOpen(true),
    []
  );

  return (
    <>
      <PlaceAutocomplete
        ref={placeAutocompleteRef}
        error={error}
        onValidPlaceChecklistSelection={handleValidPlaceChecklistSelection}
      >
        <PlaceAutocomplete.InputContainer>
          {geographyData ? (
            <GeographyTag
              onClick={handleGeographyTagClick}
              onRemove={() => setGeographyData(null)}
              customClass={css['in-desktopGeographySearch__maxWidthTag']}
            />
          ) : (
            <PlaceAutocomplete.Icon />
          )}

          <PlaceAutocomplete.Input
            placeholder={getPlaceAutocompletePlaceholder}
            onClick={openSearchOptions}
            onInput={closeSearchOptions}
          />
        </PlaceAutocomplete.InputContainer>

        <PlaceAutocomplete.SuggestionList />

        {isPlaceCheckListOpen && (
          <PlaceCheckList
            onClickOutside={() => setIsPlaceCheckListOpen(false)}
          />
        )}
      </PlaceAutocomplete>

      {searchOptionsOpen && (
        <SearchOptions>
          <SearchOptions.CurrentSearch />
          <SearchOptions.SelectOnMap />
          <SearchOptions.DrawAreaOnMap />
          <SearchOptions.DistanceFromAPoint />
        </SearchOptions>
      )}
    </>
  );
}
