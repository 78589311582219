import type { PropsWithChildren } from 'react';
import { createContext, useContext } from 'react';

const SearchModalContext = createContext<boolean | null>(null);

export function SearchModalProvider({ children }: PropsWithChildren) {
  return (
    <SearchModalContext.Provider value={true}>
      {children}
    </SearchModalContext.Provider>
  );
}

export const useIsSearchModalContextAvailable = () => {
  const context = useContext(SearchModalContext);

  return context !== null;
};
