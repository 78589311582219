import type { PolygonNamingResponse } from 'src/repository/geography/api';

import type {
  AreaSearchGeography,
  DistanceSearchCircleGeography,
} from 'src/libs/geography';

import type { AddressPartType } from 'src/types/geography';

import type { Trans } from 'src/utils/i18nFormatters';

const formatLocation = (
  isCircleDrawing: boolean,
  province?: string,
  city?: string,
  zone?: string,
  address?: AddressPartType[]
) => {
  switch (true) {
    case Boolean(address):
      return formatAddress(address as AddressPartType[], isCircleDrawing);
    case Boolean(zone) && Boolean(city):
      return `${zone} ${city}`;
    case Boolean(city) && Boolean(province): {
      let location = city;

      if (city !== province) {
        location += `, ${province}`;
      }

      return location;
    }

    case Boolean(province):
      return province;
    default:
      return '';
  }
};

export const getDrawingTagLabel = (
  values: AreaSearchGeography | DistanceSearchCircleGeography,
  trans: Trans,
  polygonAddress: PolygonNamingResponse | undefined
) => {
  const isPolygon = values.searchType === 'polygon';

  if (polygonAddress) {
    return polygonNameFormatter(
      polygonAddress,
      trans,
      isPolygon ? 'AREA' : 'CIRCLE_DRAWING',
      isPolygon ? undefined : values.value.radius,
      isPolygon ? values.value.indirizzo : undefined
    );
  }

  return values.searchType === 'circle'
    ? trans('act_draw_radius')
    : trans('search_formatter_area_drawn', { capitalize: true });
};

const formatAddress = (
  address: AddressPartType[],
  isCircleDrawing: boolean
) => {
  const routePart = address.find((addressPart) =>
    addressPart.types.find((type) => type === 'route')
  );
  const route = routePart ? routePart.long_name : '';

  const streetNumberPart = address.find((addressPart) =>
    addressPart.types.find((type) => type === 'street_number')
  );
  const streetNumber = streetNumberPart ? streetNumberPart.long_name : '';

  const administrativeAreaLevel3Part = address.find((addressPart) =>
    addressPart.types.find((type) => type === 'administrative_area_level_3')
  );
  const administrativeAreaLevel3 = administrativeAreaLevel3Part
    ? administrativeAreaLevel3Part.long_name
    : '';

  if (isCircleDrawing) {
    if (streetNumber) {
      return `${route}, ${streetNumber} • ${administrativeAreaLevel3}`;
    }

    return route
      ? `${route} • ${administrativeAreaLevel3}`
      : administrativeAreaLevel3;
  }

  return `${route}, ${administrativeAreaLevel3}`;
};

export const polygonNameFormatter = (
  response: PolygonNamingResponse,
  trans: Trans,
  searchType: 'CIRCLE_DRAWING' | 'AREA',
  radius = 0,
  addressValue: string | undefined // Address coming from search-list
): string => {
  const province = response.province;
  let city = response.city;
  const zone = response.zone;
  const address = response.address;

  if (!city && address) {
    const addressCity = address.find((addressItem) =>
      addressItem.types.find((tag) => tag === 'administrative_area_level_3')
    );

    if (addressCity) {
      city = addressCity.long_name;
    }
  }

  if (addressValue) {
    // Address search tag should be different from the others
    let address = `${addressValue
      .split('-')
      .map((str) => str.charAt(0).toUpperCase() + str.slice(1))
      .join(' ')}`;

    if (city) {
      address += ` • ${city}`;
    }

    return address;
  }

  const label =
    searchType === 'AREA'
      ? trans('search_formatter_area_drawn')
      : trans('lbl_circle');

  const formattedLabel = label.charAt(0).toUpperCase() + label.slice(1);

  if (!province && !city && !zone && !address) {
    return formattedLabel;
  }

  const formattedLocation = formatLocation(
    searchType === 'CIRCLE_DRAWING',
    province,
    city,
    zone,
    address
  );

  const currentLocale = document.documentElement.lang.replace('_', '-');

  const distance = radius
    ? Intl.NumberFormat(currentLocale).format(
        radius >= 1000
          ? parseFloat((radius / 1000).toFixed(2))
          : parseFloat(radius.toFixed(0))
      )
    : 0;

  return searchType === 'AREA'
    ? `${formattedLabel} • ${formattedLocation} `
    : `${distance} ${radius >= 1000 ? 'Km' : 'm'} ${trans(
        'lbl_from'
      )} ${formattedLocation}`;
};
