import type { RefObject } from 'react';
import { createContext, useContext } from 'react';

import type {
  BaseEntityWithChildren,
  PlaceAutocompleteResponse,
} from './types';

interface PlaceAutocompleteContextValue {
  autocomplete: {
    autocomplete: PlaceAutocompleteResponse;
    value: BaseEntityWithChildren | null;
  };
  inputRef: RefObject<HTMLInputElement>;
  hasFixedHeight?: boolean;
  error?: boolean;
}

export const PlaceAutocompleteContext =
  createContext<PlaceAutocompleteContextValue | null>(null);

export const usePlaceAutocompleteContext = () => {
  const context = useContext(PlaceAutocompleteContext);

  if (!context) {
    throw new Error(
      'usePlaceAutocompleteContext must be used within a PlaceAutocomplete component'
    );
  }

  return context;
};
