import { useAtom } from 'jotai';

import { useAtomicStateStateAtom } from 'src/atoms/atomic-state';
import { geographySearchAtom, tempGeographySearchAtom } from '../atoms';

import { useIsSearchModalContextAvailable } from '../SearchModal/context';

/**
 * This hook deals with centralizing the logic of the `geographySearch` and
 * `tempGeographySearch` atoms. If the requesting component is a child of the
 * `SearchModal` component, the hook will provide the temporary geography data,
 * otherwise it will provide the current geography data.
 */
export const useGeographySearchAtom = () => {
  const [geographySearch, setGeographySearch] =
    useAtomicStateStateAtom(geographySearchAtom);
  const [tempGeographySearch, setTempGeographySearch] = useAtom(
    tempGeographySearchAtom
  );
  const isSearchModalContextAvailable = useIsSearchModalContextAvailable();

  return isSearchModalContextAvailable
    ? ([tempGeographySearch, setTempGeographySearch] as const)
    : ([geographySearch, setGeographySearch] as const);
};
