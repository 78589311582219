import { isFeatureEnabled } from 'src/config/features-toggle';

import type { Trans } from 'src/utils/i18nFormatters';

export const getMobileSearchPlaceholder = (trans: Trans) => {
  const isSearchMetroDisabled = isFeatureEnabled('SEARCH_NO_METRO');

  if (isSearchMetroDisabled) {
    return trans('insert_province_city_zones_without_metro');
  }

  return trans('insert_province_city_zones');
};
