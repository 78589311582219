import { useEffect, useRef } from 'react';

import { isPlaceSearchGeography } from 'src/libs/geography';
import { PepitaCheckbox } from 'src/libs/ui/pepita-checkbox';

import type { BaseEntityWithChildren } from 'src/types/geography';
import { ENTITY_TYPE, type GeographyValue } from 'src/types/geography';

import { getChildrenStatus, getItemStatus } from './utils';

import { useGeographySearchAtom } from '../../hooks/useGeographySearchAtom';

import css from './styles.module.scss';

interface PlaceCheckListAccordionTextProps {
  item: BaseEntityWithChildren;
  handleSelection: (item: GeographyValue, checked: boolean) => void;
}

export function AccordionText({
  item,
  handleSelection,
}: PlaceCheckListAccordionTextProps) {
  const ref = useRef<HTMLDivElement>(null);
  const [geographySearch] = useGeographySearchAtom();

  const placeSearchGeography =
    geographySearch && isPlaceSearchGeography(geographySearch)
      ? geographySearch
      : null;

  const isChecked = getItemStatus(placeSearchGeography, item);
  const areSomeChildrenChecked = getChildrenStatus(placeSearchGeography, item);

  useEffect(() => {
    if (isChecked || areSomeChildrenChecked) {
      const parent = ref.current?.parentNode as HTMLDivElement;

      parent.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [isChecked, areSomeChildrenChecked]);

  return (
    <div
      role="button"
      ref={ref}
      tabIndex={0}
      onKeyDown={(event) => {
        if (event.key === 'Enter' || event.keyCode === 13) {
          handleSelection(item, isChecked);
        }
      }}
      className={css['in-placeCheckListAccordionText']}
      onClick={(event) => {
        event.preventDefault();
        event.stopPropagation();
        handleSelection(item, isChecked);
      }}
    >
      <PepitaCheckbox
        checked={isChecked}
        onChange={(event) => {
          handleSelection(item, event.target.checked);
        }}
        indeterminate={item.type !== ENTITY_TYPE.city && areSomeChildrenChecked}
      >
        {item.label}
      </PepitaCheckbox>
    </div>
  );
}
