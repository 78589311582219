import clsx from 'clsx';

import { isPlaceSearchGeography } from 'src/libs/geography';
import { PepitaCheckbox } from 'src/libs/ui/pepita-checkbox';

import type { BaseEntity, GeographyValue } from 'src/types/geography';

import { useGeographySearchAtom } from '../../hooks/useGeographySearchAtom';
import { isSameEntity } from '../../utils/entity';

import css from './styles.module.scss';

export function AccordionContent({
  item,
  handleSelection,
}: {
  item: BaseEntity[];
  handleSelection: (item: GeographyValue, checked: boolean) => void;
}) {
  const [geographySearch] = useGeographySearchAtom();

  const placeSearchGeography =
    geographySearch && isPlaceSearchGeography(geographySearch)
      ? geographySearch
      : null;

  return (
    <ul className="nd-stack">
      {item.map((child, index) => (
        <li
          className={clsx(
            'nd-stackItem',
            css['in-placeCheckListAccordionContent__item']
          )}
          key={index}
        >
          <PepitaCheckbox
            checked={placeSearchGeography?.value.some(
              (entity) =>
                isSameEntity(entity, child) ||
                isSameEntity(entity, child.parents[0]) // A child is selected if is in current selection or if its parent is selected
            )}
            onChange={(event) => {
              handleSelection(child, event.target.checked);
            }}
            customClass={css['in-placeCheckListAccordionContent__checkbox']}
            controlCustomClass={
              css['in-placeCheckListAccordionContent__controlCheckbox']
            }
          >
            {child.label}
          </PepitaCheckbox>
        </li>
      ))}
    </ul>
  );
}
