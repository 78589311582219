import { useEffect, useState } from 'react';
import { useTranslations } from '@pepita-react/i18n';
import clsx from 'clsx';

import { useGeographySearchAtom } from '../hooks/useGeographySearchAtom';
import { usePolygonNamingApi } from '../hooks/usePolygonNamingApi';

import { isDistanceSearchIsolineGeography } from 'src/libs/geography';
import { PepitaTag } from 'src/libs/ui/pepita-tag';

import { getDrawingTagLabel } from '../utils/addresses';
import { getGeographyTagLabel } from '../utils/labels';

import { useIsGeographyFeatureEnabled } from '../FeaturesProvider';

import css from './styles.module.scss';

interface GeographyTagProps {
  hasFixedHeight?: boolean;
  customClass?: string;
  onClick?: () => void;
  onRemove?: () => void;
}

export function GeographyTag({
  hasFixedHeight = false,
  customClass,
  onClick,
  onRemove,
}: GeographyTagProps) {
  const { trans } = useTranslations();
  const [label, setLabel] = useState<Nullable<string>>();
  const [geographySearch] = useGeographySearchAtom();

  const isGeographyFeatureEnabled = useIsGeographyFeatureEnabled();

  /** Effect for setting drawings label **/
  const polygonName = usePolygonNamingApi(geographySearch);

  useEffect(() => {
    if (!geographySearch) {
      setLabel(null);

      return;
    }

    // Place search
    if (geographySearch.searchType === 'place') {
      setLabel(
        getGeographyTagLabel(
          geographySearch.value,
          isGeographyFeatureEnabled('labelsForCountry'),
          trans
        )
      );

      return;
    }

    // Distance search isoline
    if (isDistanceSearchIsolineGeography(geographySearch)) {
      setLabel(geographySearch.value.label);

      return;
    }

    // Distance search circle and Area search
    setLabel(getDrawingTagLabel(geographySearch, trans, polygonName));
  }, [geographySearch, isGeographyFeatureEnabled, polygonName, trans]);

  return label ? (
    <div
      className={clsx(
        css['in-geographyTags'],
        hasFixedHeight && css['has-fixedHeight'],
        customClass
      )}
      title={label}
    >
      <PepitaTag
        onClick={onClick}
        onRemove={() => {
          if (onRemove) {
            onRemove();
          }
        }}
        customClass={css['in-geographyTags__tag']}
      >
        {label}
      </PepitaTag>
    </div>
  ) : null;
}
