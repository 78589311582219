import { useCallback, useMemo } from 'react';
import SearchSpriteUrl from '@pepita-fe/sprite-search-module/sprite.svg';
import { useTranslations } from '@pepita-react/i18n';
import { useSetAtom } from 'jotai';

import { useAtomicStateAtomValue } from 'src/atoms/atomic-state';

import {
  isDistanceSearchGeography,
  isDistanceSearchIsolineGeography,
  isPlaceSearchGeography,
} from 'src/libs/geography';
import { PepitaButton } from 'src/libs/ui/pepita-button';
import type { PepitaIconProps } from 'src/libs/ui/pepita-icon';
import { PepitaIcon } from 'src/libs/ui/pepita-icon';

import { geographySearchAtom } from '../../atoms';
import { comeFromSearchOptionsAtom } from '../../atoms/comeFromSearchOptionsAtom';
import { useIsGeographyFeatureEnabled } from '../../FeaturesProvider';
import { useComponentControls } from '../../hooks/useComponentControls';
import { usePolygonNamingApi } from '../../hooks/usePolygonNamingApi';
import { getDrawingTagLabel } from '../../utils/addresses';
import { isValidPlaceChecklistSelection } from '../../utils/entity';
import { getGeographyTagLabel } from '../../utils/labels';

import css from './styles.module.scss';

export function CurrentSearch() {
  const { trans } = useTranslations();
  const geographyData = useAtomicStateAtomValue(geographySearchAtom);
  const isGeographyFeatureEnabled = useIsGeographyFeatureEnabled();
  const { openSearchModal } = useComponentControls();
  const setComeFromSearchOptions = useSetAtom(comeFromSearchOptionsAtom);
  /** Effect for setting drawings label **/
  const polygonLabel = usePolygonNamingApi(geographyData);

  const currentSelection: { label: string; icon: PepitaIconProps } | undefined =
    useMemo(() => {
      if (!geographyData) return;

      // Place search
      if (geographyData.searchType === 'place') {
        return {
          icon: {
            name: 'zone',
          },
          label: getGeographyTagLabel(
            geographyData.value,
            isGeographyFeatureEnabled('labelsForCountry'),
            trans
          ),
        };
      }

      // Distance search circle
      if (geographyData.searchType === 'circle') {
        return {
          icon: {
            name: 'point',
          },
          label: getDrawingTagLabel(geographyData, trans, polygonLabel),
        };
      }

      if (isDistanceSearchIsolineGeography(geographyData)) {
        return {
          icon: {
            name: 'point',
          },
          label: geographyData.value.label,
        };
      }

      // Area search
      return {
        icon: {
          name: 'draw',
        },
        label: getDrawingTagLabel(geographyData, trans, polygonLabel),
      };
    }, [geographyData, isGeographyFeatureEnabled, polygonLabel, trans]);

  const handleEditClick = useCallback(() => {
    if (!geographyData) return;

    if (
      isPlaceSearchGeography(geographyData) &&
      isValidPlaceChecklistSelection(geographyData)
    ) {
      openSearchModal('PLACE');

      return;
    }

    if (isDistanceSearchGeography(geographyData)) {
      openSearchModal('DISTANCE');
      setComeFromSearchOptions(true);

      return;
    }

    openSearchModal('AREA');
    setComeFromSearchOptions(true);
  }, [geographyData, openSearchModal, setComeFromSearchOptions]);

  if (!currentSelection) {
    return null;
  }

  return (
    <div className={css['in-currentSearch__container']}>
      <p className={css['in-currentSearch__title']}>
        {trans('geography_search_current_search_label')}
      </p>
      <div className={css['in-currentSearch__element']}>
        <PepitaIcon
          name={currentSelection.icon.name}
          spriteUrl={SearchSpriteUrl}
          customClass={css['in-currentSearch__icon']}
        />
        <span className={css['in-currentSearch__text']}>
          {currentSelection.label}
        </span>
        <PepitaButton
          variant="link"
          small
          onClick={handleEditClick}
          customClass={css['in-currentSearch__button']}
        >
          {trans('act_edit')}
        </PepitaButton>
      </div>
      <p className={css['in-currentSearch__title']}>
        {trans('geography_search_new_search_label')}
      </p>
    </div>
  );
}
