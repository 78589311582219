import type { BaseEntityWithChildren } from 'src/types/geography';
import type { GeographyValue } from 'src/types/geography';

import {
  areAllLeavesSelected,
  getEntityChildByID,
  isChildOf,
  isSameEntity,
} from '../../utils/entity';

/**
 * Handle the selection of a leaf inside the city check list
 * For leaf we mean the minimum selectable entity (microzone or metro)
 * @param item current item changed by the user
 * @param checked if the item had been checked or not
 * @param city the current check list city
 * @param currentSelection the array of current selected values
 */
export const handleLeafSelection = (
  item: GeographyValue,
  checked: boolean,
  city: GeographyValue,
  currentSelection: GeographyValue[]
) => {
  const parent = getEntityChildByID(
    city,
    item.parents[0]?.id,
    item.parents[0]?.type
  );

  if (!checked) {
    // Removing the current item or its parent if all leaves are selected
    if (
      parent &&
      currentSelection.find((value) => isSameEntity(parent, value))
    ) {
      // We need to remove the parent and to add all other children except the current one
      const newSelection = currentSelection.filter(
        (value) => !isSameEntity(parent as BaseEntityWithChildren, value)
      );

      newSelection.push(
        ...(parent.children
          ? parent.children.filter((child) => !isSameEntity(child, item))
          : [])
      );

      return newSelection;
    }

    return currentSelection.filter((value) => !isSameEntity(item, value));
  }

  // Adding the current item
  const tmpSelection = [...currentSelection, item];

  if (parent && areAllLeavesSelected(parent, tmpSelection)) {
    // We need to remove all leaves and to add the parent
    const allLeaves = parent.children;
    const newSelection = tmpSelection.filter(
      (value) => !allLeaves?.find((leaf) => isSameEntity(leaf, value))
    );

    newSelection.push(parent);

    return newSelection;
  }

  return tmpSelection;
};

/**
 * Handle the selection of a parent (macrozone or metro line) inside the city
 * check list
 * @param item current item changed by the user
 * @param checked if the item had been checked or not
 * @param city the current check list city
 * @param currentSelection the array of current selected values
 */
export const handleParentSelection = (
  item: GeographyValue,
  checked: boolean,
  city: GeographyValue,
  currentSelection: GeographyValue[]
) => {
  if (checked) {
    // Removing old value
    const newSelection = currentSelection.filter(
      (value) => !isSameEntity(item, value)
    );

    if (!newSelection.length) {
      return [city];
    }

    return newSelection;
  }

  // Removing all the children for this entity
  const newSelection = currentSelection.filter(
    (value) => !isChildOf(item, value)
  );

  newSelection.push(item);

  return newSelection;
};
