import { memo, useCallback, useEffect, useRef } from 'react';
import type { GeoJSON } from '@indomita-website/search/types';
import SearchSpriteUrl from '@pepita-fe/sprite-search-module/sprite.svg';
import { useTranslations } from '@pepita-react/i18n';
import { clsx } from 'clsx';
import { useAtomValue } from 'jotai';

import { useAtomicStateStateAtom } from 'src/atoms/atomic-state';

import { geographySearchAtom } from 'src/components/ReactGeographySearch/atoms';

import { isFeatureEnabled } from 'src/config/features-toggle';
import { getProductConfig } from 'src/config/product';

import { searchInfoAtom } from 'src/entities/listing/atoms/searchInfoAtom';

import { useNumberFormatterCallback } from 'src/hooks/useFormatters';
import { useIsomorphicLayoutEffect } from 'src/hooks/useIsomorphicLayoutEffect';
import { useMapQueryParams } from 'src/hooks/useMapQueryParams';
import { usePepitaCustomElementImport } from 'src/hooks/usePepitaCustomElement';
import { useGeographySearchTracking } from './useGeographySearchTracking';

import type { GeographySearch as GeographySearchData } from 'src/libs/geography';
import { PepitaIcon, usePepitaInlineSvgSprite } from 'src/libs/ui/pepita-icon';

import type { BaseEntityWithChildren, GeographySearchVRT } from './types';

import { geographyVRTToSearchData, searchDataToGeographyVRT } from './utils';

const mapConfig = getProductConfig('mapConfig');

const isSamePlaceGeography = (
  geography1: GeographySearchData,
  geography2: GeographySearchData
): boolean =>
  geography1?.searchType === 'place' &&
  geography1?.searchType === geography2?.searchType &&
  geography2?.value?.length === geography1?.value?.length &&
  geography2?.value?.every((newValue) =>
    Boolean(
      geography1?.value?.find(
        (oldValue) =>
          newValue.type === oldValue.type && newValue.id === oldValue.id
      )
    )
  );

export const GeographySearch = memo(
  ({
    drawEnabled,
    metroEnabled,
    regionSearchEnabled,
    countrySearchEnabled,
    internationalEnabled,
    searchMetroDisabled,
    customClass,
    error,
    autorefill,
    onAutoSubmit,
    defaultEmptyLabel,
    showResultsCountButton,
    onAutocompleteSelection,
  }: {
    drawEnabled?: boolean;
    metroEnabled?: boolean;
    regionSearchEnabled: boolean;
    countrySearchEnabled: boolean;
    internationalEnabled: boolean;
    searchMetroDisabled?: boolean;
    customClass?: string;
    error?: boolean;
    autorefill?: boolean;
    onAutoSubmit?: (values: BaseEntityWithChildren[] | GeoJSON) => void;
    onAutocompleteSelection?: () => void;
    defaultEmptyLabel?: string;
    showResultsCountButton?: boolean;
  }) => {
    const { trans } = useTranslations();

    const params = useMapQueryParams();

    const ready = usePepitaCustomElementImport(
      () =>
        import(
          '@indomita-website/search/components/GeographySearch' /* webpackChunkName: "geography-search" */
        )
    );

    const ref = useRef<any>();
    const inputRef = useRef<HTMLInputElement>(null);
    const [geography, setGeography] =
      useAtomicStateStateAtom(geographySearchAtom);

    const { trackSearchbarZonesButtonPressed } = useGeographySearchTracking();

    const onResultsCountClick = useCallback(() => {
      trackSearchbarZonesButtonPressed();
    }, [trackSearchbarZonesButtonPressed]);

    function updateCustomElementState() {
      if (!ready) {
        return;
      }

      const el = ref.current;

      el.onResultsCountClick = onResultsCountClick;
      el.onAutocompleteSelection = onAutocompleteSelection;

      if (!geography) {
        el.deleteSelection();
      }

      if (geography?.searchType !== 'place') {
        const vrt = searchDataToGeographyVRT(geography);

        if (vrt) {
          el.setSelectionValue(vrt);
        }

        return;
      }

      // You can arrive here only for place searches
      // First of all we need to know if new data is the same of the
      // previous search
      const current = el.getSelectionValue();

      if (
        current?.searchType === 'place' &&
        current?.value?.length === geography.value.length &&
        current?.value.every((a) =>
          geography.value.find((b) => b.id === a.id && b.type === a.type)
        ) &&
        current.idMacroarea &&
        current.idMacroarea === geography?.idMacroarea
      ) {
        return;
      }

      el.setSelectionValue({
        searchType: geography?.searchType,
        value: geography.value,
        idMacroarea: geography?.idMacroarea || null,
      });
    }

    useEffect(() => {
      if (!params || !params?.lat || !params?.lng) return;
      if (!ref.current) return;

      ref.current.setMapConfig?.({
        center: {
          lat: params.lat,
          lng: params.lng,
        },
        zoom: params.zoom,
      });
    }, [params, ref.current]);

    useIsomorphicLayoutEffect(() => {
      updateCustomElementState();
    }, [ref, ready, geography]);

    useEffect(() => {
      if (!ready) {
        return;
      }

      const el = ref.current;

      el.onLocationChange = (
        state: GeographySearchData | GeographySearchVRT
      ) => {
        /**
         * when the user deletes the value we don't do any search if autorefill
         * is enabled
         */
        if (state.value) {
          if (state.searchType === 'place') {
            // search by zone or metro
            const newGeography = {
              searchType: state.searchType,
              value: state.value.map((e) => ({
                ...e,
                parents: e.parents ?? [],
                children: e.children ?? [],
              })),
            };

            if (!isSamePlaceGeography(geography, newGeography)) {
              setGeography(newGeography);
            }
          } else if (state.searchType === 'vrt') {
            setGeography(geographyVRTToSearchData(state));
          }
        } else if (!autorefill) {
          setGeography(null);
        }
      };
    }, [ref, ready, setGeography, geography, autorefill]);

    useEffect(() => {
      if (!ready || !onAutoSubmit) return;
      const el = ref.current;

      el.onAutoSubmit = onAutoSubmit;
    }, [ready, setGeography, onAutoSubmit]);

    usePepitaInlineSvgSprite(SearchSpriteUrl);

    const { resultsCount, isFetching } = useAtomValue(searchInfoAtom) || {};

    const formatNumber = useNumberFormatterCallback();

    const getResultsCountLabel = useCallback(() => {
      if (isFetching) return;

      if (resultsCount && resultsCount > 0) {
        return trans('act_show_all_result', {
          count: resultsCount,
          params: [formatNumber(resultsCount, 0)],
        });
      }

      return trans('act_no_result');
    }, [formatNumber, isFetching, resultsCount, trans]);

    const resultsCountData = {
      isLoading: isFetching,
      label: getResultsCountLabel(),
    };

    const isGeographyItaly =
      geography?.searchType === 'place' &&
      geography.value.length === 1 &&
      geography.value[0].id === 'IT';

    // Since the backend doesn't send us the italian polygon, to avoid breaking
    // the GeographySearch component when it try to edit the undefined polygon,
    // we need to disable the edit functionality.
    const showCurrentSearchEditButton = isGeographyItaly ? false : true;

    return (
      <nd-geography-search
        class={clsx('nd-autocomplete im-geographySearch', customClass)}
        drawenabled={drawEnabled}
        metroenabled={metroEnabled}
        autorefill={autorefill}
        searchMetroDisabled={searchMetroDisabled}
        regionSearchEnabled={regionSearchEnabled}
        countrySearchEnabled={countrySearchEnabled}
        internationalEnabled={internationalEnabled}
        internationalLabelsEnabled={isFeatureEnabled(
          'INTERNATIONAL_LABELS_ENABLED'
        )}
        alternatePolygonsApiEnabled={isFeatureEnabled('FIX_ALTERNATE_POLYGONS')}
        modalAutoSubmit={Boolean(onAutoSubmit)}
        defaultEmptyLabel={defaultEmptyLabel}
        ref={ref}
        data-cy="geography-search"
        resultsCount={
          showResultsCountButton && JSON.stringify(resultsCountData)
        }
        showCurrentSearchEditButton={showCurrentSearchEditButton}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: `<!--mapConfig${JSON.stringify(mapConfig)}-->`,
          }}
        ></div>
        {error ? (
          <PepitaIcon
            name="exclamation-mark"
            customClass="im-geographySearch__icon im-geographySearch__icon--error"
          />
        ) : null}
        {ready ? (
          <PepitaIcon
            name="lens"
            customClass="im-geographySearch__icon js-geographySearch__lens"
          />
        ) : null}
        <input
          ref={inputRef}
          className="nd-autocomplete__input"
          type="text"
          placeholder={ready ? trans('insert_province_city_zones') : ''}
          data-hj-whitelist
          aria-label={trans('insert_province_city_zones')}
        />
      </nd-geography-search>
    );
  }
);

GeographySearch.displayName = 'GeographySearch';
