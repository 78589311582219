import { useMemo } from 'react';
import clsx from 'clsx';

import { useListener } from 'src/hooks/useListener';

import { usePlaceAutocompleteContext } from '../context';

import css from './styles.module.scss';

interface PlaceInputProps {
  placeholder: string | ((isFocused: boolean) => string);
  customClass?: string;
  onClick?: () => void;
  onInput?: () => void;
  onFocus?: () => void;
  onBlur?: () => void;
}

export function Input({
  placeholder,
  customClass,
  onClick,
  onInput,
  onFocus,
  onBlur,
}: PlaceInputProps) {
  const {
    autocomplete: { autocomplete },
    error,
    hasFixedHeight,
    inputRef,
  } = usePlaceAutocompleteContext();

  const { inFocus } = autocomplete;

  useListener(inputRef, 'change', (evt) => {
    evt.stopPropagation();
  });

  const placeholderValue = useMemo(() => {
    if (typeof placeholder === 'function') {
      return placeholder(inFocus);
    }

    return placeholder;
  }, [placeholder, inFocus]);

  return (
    <input
      className={clsx(
        'nd-autocomplete__input',
        css['in-placeInput'],
        error && css['is-error'],
        hasFixedHeight && css['has-fixedHeight'],
        customClass
      )}
      ref={inputRef}
      type="text"
      onClick={onClick}
      onInput={onInput}
      onFocus={onFocus}
      onBlur={onBlur}
      placeholder={placeholderValue}
      data-hj-whitelist
      data-cy="geography-search"
    />
  );
}
