import { useEffect, useRef, useState } from 'react';
import { useTranslations } from '@pepita-react/i18n';
import clsx from 'clsx';

import { useClickOutside } from 'src/hooks/useClickOutside';
import { useGeographySearchAtom } from '../hooks/useGeographySearchAtom';
import { useGetEntityWithChildren } from '../hooks/useGetEntityWithChildren';

import { PepitaButton } from 'src/libs/ui/pepita-button';

import type { BaseEntityWithChildren } from 'src/types/geography';

import { isMetro } from '../utils/entity';

import { CheckListAccordion } from './CheckListAccordion';

interface PlaceCheckListProps {
  customClass?: string;
  onClickOutside?: () => void;
}

export function PlaceCheckList({
  customClass,
  onClickOutside,
}: PlaceCheckListProps) {
  const { trans } = useTranslations();
  const componentRef = useRef<HTMLDivElement>(null);
  const [geographySearch] = useGeographySearchAtom();

  const [checklistData, setChecklistData] = useState<BaseEntityWithChildren[]>(
    []
  );

  const city = useGetEntityWithChildren();

  useEffect(() => {
    setChecklistData(city?.children || []);
  }, [city]);

  const headerText: string =
    geographySearch && isMetro(geographySearch.value[0])
      ? trans('lbl_metro')
      : trans('lbl_zones');

  useClickOutside(componentRef, () => {
    if (onClickOutside) onClickOutside();
  });

  return geographySearch && checklistData.length > 0 && city ? (
    <div
      data-cy="zones-autocomplete-list"
      ref={componentRef}
      className={clsx('nd-autocomplete__list', 'is-open', customClass)}
    >
      <div className="nd-autocomplete__listHead">
        <span className="nd-autocomplete__listHeadTitle">{headerText}</span>

        <PepitaButton variant="link">
          {isMetro(geographySearch.value[0])
            ? trans('search_map_header_metro_title')
            : trans('search_map_header_select_place_title')}
        </PepitaButton>
      </div>

      <CheckListAccordion checklistData={checklistData} city={city} />
    </div>
  ) : null;
}
