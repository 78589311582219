import type { PlaceSearchGeography } from 'src/libs/geography';

import type { BaseEntityWithChildren } from 'src/types/geography';

import { isSameEntity } from '../../utils/entity';

export const getItemStatus = (
  placeSearchGeography: PlaceSearchGeography | null,
  item: BaseEntityWithChildren
) => {
  if (!placeSearchGeography) return false;

  return placeSearchGeography.value.some((entity) =>
    isSameEntity(entity, item)
  );
};

export const getChildrenStatus = (
  placeSearchGeography: PlaceSearchGeography | null,
  item: BaseEntityWithChildren
) => {
  if (!placeSearchGeography) return false;

  const itemIsNotInGeography = placeSearchGeography.value.some(
    (entity) => !isSameEntity(entity, item)
  );

  const someChildrenAreInGeography =
    item.children?.some((child) =>
      placeSearchGeography.value.find((e) => isSameEntity(e, child))
    ) || false;

  return itemIsNotInGeography && someChildrenAreInGeography;
};
