import {
  useAuthContext,
  useUserAdditionalData,
} from '@indomita-react/auth-provider';
import { useIsOnClient } from '@indomita-react/custom-hooks';
import { UserMenu } from '@indomita-react/user-menu';
import { useTranslations } from '@pepita-react/i18n';
import { clsx } from 'clsx';
import Head from 'next/head';
import { useRouter } from 'next/router';

import { useAtomicStateAtomValue } from 'src/atoms/atomic-state';

import { AuthButton } from 'src/components/AuthButton';
import { GeographySearch } from 'src/components/GeographySearch';
import { ReactGeographySearch } from 'src/components/ReactGeographySearch';
import { geographySearchAtom } from 'src/components/ReactGeographySearch/atoms';

import { isFeatureEnabled } from 'src/config/features-toggle';
import { getProductConfig } from 'src/config/product';

import { useHeaderLinkConfig } from 'src/hooks/useHeaderLink';
import { useUserConfigLink } from 'src/hooks/useUserConfigLink';

import { PepitaBadge } from 'src/libs/ui/pepita-badge';
import { PepitaButton } from 'src/libs/ui/pepita-button';
import { PepitaIcon } from 'src/libs/ui/pepita-icon';

import type { Language } from 'src/types/translations';

import { absoluteURL, alternateAbsoluteURL } from 'src/utils/url';

import type { HeaderItemProps } from './HeaderItem';
import { HeaderItem } from './HeaderItem';

import css from './index.module.scss';

const HeaderLogoConfig = getProductConfig('logoConfig');
const HeaderPublishAdsUrl = getProductConfig('publishedAdsLandingUrl');
const HeaderUserInfo = getProductConfig('user');
const domainName = getProductConfig('domainName');
const geographySearchConfig = getProductConfig('geographySearch');
const searchMetroDisabled = isFeatureEnabled('SEARCH_NO_METRO');

export type HeaderProps = {
  boxed?: boolean;
  customClass?: string;
  selectedSection?: 'SAVED_REAL_ESTATES' | 'MESSAGING';
  backNavOnClick?: () => void;
  showBrand?: boolean;
  showSearch?: boolean;
  showUser?: boolean;
  showMenu?: boolean;
  menuItems?: Nullable<HeaderItemProps[]>;
  showUserOnMobile?: boolean;
  isLogoClickServerSideNavigation?: boolean;
  isBranded?: boolean;
};

export function Header({
  boxed = false,
  customClass,
  selectedSection,
  backNavOnClick,
  showBrand = false,
  showSearch = false,
  showUser = false,
  showMenu = false,
  menuItems,
  showUserOnMobile = false,
  isLogoClickServerSideNavigation = false,
  isBranded = false,
}: HeaderProps) {
  const { trans } = useTranslations();
  const { defaultLocale, locale } = useRouter();
  const geography = useAtomicStateAtomValue(geographySearchAtom);

  const { user } = useAuthContext();
  const { counters } = useUserAdditionalData();
  const isOnClient = useIsOnClient();

  const headerLinkConfig = useHeaderLinkConfig();

  const menuLinks = menuItems || headerLinkConfig;

  const { linkConfig, mobileConfig, onLoginClick } = useUserConfigLink();

  const logoUrl = isBranded
    ? HeaderLogoConfig.url
    : HeaderLogoConfig.positiveUrl;

  const logoUrlMobile = isBranded
    ? HeaderLogoConfig.mobile.altURL
    : HeaderLogoConfig.mobile.positiveURL;

  return (
    <header
      className={clsx(
        HeaderStyles.element,
        css[HeaderStyles.inElement],
        css['in-header'],
        css[HeaderStyles.inElement],
        css['in-header'],
        !isBranded && css['in-header--white'],
        !showSearch && !isBranded && css['has-border'],
        customClass
      )}
    >
      <nav className={clsx(HeaderStyles.row, boxed && HeaderStyles.boxedRow)}>
        {backNavOnClick && (
          <section
            className={clsx(
              HeaderStyles.section,
              `${HeaderStyles.section}--menu`
            )}
          >
            <HeaderItem
              customClass={`${HeaderStyles.menuBack}`}
              onClick={backNavOnClick}
            >
              <PepitaIcon name="arrow-left" />
            </HeaderItem>
          </section>
        )}
        {showBrand && (
          <>
            {/* preload logos */}
            <Head>
              <link
                rel="preload"
                href={logoUrl}
                as="image"
                type="image/svg+xml"
                fetchpriority="high"
              />
              <link
                rel="preload"
                href={logoUrlMobile}
                as="image"
                type="image/svg+xml"
                fetchpriority="high"
              />
            </Head>
            <section
              className={clsx(
                HeaderStyles.section,
                `${HeaderStyles.section}--brand`,
                showSearch && HeaderStyles.brandWithSearch
              )}
            >
              <HeaderItem
                link={{
                  url: alternateAbsoluteURL(
                    '/',
                    defaultLocale as Language,
                    locale as Language
                  ),
                }}
                customClass={css['in-header__logo']}
                props={{ 'aria-label': domainName }}
                isClientSideNavigation={!isLogoClickServerSideNavigation}
              >
                <picture className={HeaderStyles.logo}>
                  {showSearch && (
                    <source
                      media="(max-width: 1023px)"
                      srcSet={logoUrlMobile}
                      width={HeaderLogoConfig.mobile.width}
                      height={HeaderLogoConfig.mobile.height}
                    />
                  )}
                  <img src={logoUrl} alt={domainName} />
                </picture>
              </HeaderItem>
            </section>
          </>
        )}
        {showSearch && (
          <section
            className={clsx(
              HeaderStyles.section,
              `${HeaderStyles.section}--search`
            )}
          >
            <HeaderItem customClass={HeaderStyles.search}>
              {isFeatureEnabled('REACT_GEOGRAPHY_SEARCH') ? (
                <ReactGeographySearch
                  featureToggles={{
                    metroSearchEnabled: !isFeatureEnabled('SEARCH_NO_METRO'),
                    regionSearchEnabled: isFeatureEnabled(
                      'REGION_SEARCH_ENABLED'
                    ),
                    countrySearchEnabled: isFeatureEnabled(
                      'COUNTRY_SEARCH_ENABLED'
                    ),
                    internationalSearchEnabled: isFeatureEnabled(
                      'INTERNATIONAL_SEARCH_ENABLED'
                    ),
                    labelsForCountry: isFeatureEnabled(
                      'INTERNATIONAL_LABELS_ENABLED'
                    ),
                  }}
                  customClass={clsx(
                    !isBranded && css['in-header__search--white']
                  )}
                />
              ) : (
                <GeographySearch
                  drawEnabled={geographySearchConfig.drawEnabled}
                  metroEnabled={geographySearchConfig.metroEnabled}
                  regionSearchEnabled={isFeatureEnabled(
                    'REGION_SEARCH_ENABLED'
                  )}
                  countrySearchEnabled={isFeatureEnabled(
                    'COUNTRY_SEARCH_ENABLED'
                  )}
                  internationalEnabled={isFeatureEnabled(
                    'INTERNATIONAL_SEARCH_ENABLED'
                  )}
                  searchMetroDisabled={searchMetroDisabled}
                  autorefill={true}
                  showResultsCountButton
                  defaultEmptyLabel={
                    !geography ? trans('geo_map_area') : undefined
                  }
                  customClass={clsx(
                    !isBranded && css['in-header__search--white']
                  )}
                />
              )}
            </HeaderItem>
          </section>
        )}
        {showMenu && (
          <section
            className={clsx(
              HeaderStyles.section,
              `${HeaderStyles.section}--menu`
            )}
          >
            {menuLinks &&
              menuLinks.map((item: HeaderItemProps, i: number) => {
                return (
                  <HeaderItem key={i} {...item}>
                    {item.icon && item.icon.name ? (
                      <>
                        <PepitaIcon name={item.icon.name} />
                        <span className={HeaderStyles.iconText}>
                          {item.text}
                        </span>
                      </>
                    ) : (
                      item.text
                    )}
                    {item.isNew && (
                      <PepitaBadge
                        position="topRight"
                        customClass={css['in-header__badge']}
                        variant="promotion"
                      >
                        {/* For product requests, we do not put the translation key but a static label for any product/language */}
                        New
                      </PepitaBadge>
                    )}
                  </HeaderItem>
                );
              })}
          </section>
        )}
        {showUser && (
          <section
            className={clsx(
              HeaderStyles.section,
              `${HeaderStyles.section}--user`,
              !showUserOnMobile && HeaderStyles.hideOnMobile
            )}
          >
            {HeaderPublishAdsUrl &&
            isFeatureEnabled('SHOW_HEADER_PUBLISH_BTN') ? (
              <HeaderItem
                visibleOnScreen="medium"
                customClass={HeaderStyles.publishBtn}
              >
                <PepitaButton
                  as="a"
                  customClass={clsx(
                    HeaderStyles.btnText,
                    !isBranded && css['in-header__button']
                  )}
                  href={absoluteURL(`${HeaderPublishAdsUrl}?from=navbar`)}
                >
                  <span className={HeaderStyles.showOnWideScreen}>
                    {trans('act_publish_ads')}
                  </span>
                  <span className={HeaderStyles.hideOnWideScreen}>
                    {trans('act_publish')}
                  </span>
                </PepitaButton>
              </HeaderItem>
            ) : null}

            {user ? (
              <>
                {HeaderUserInfo.savedAdsUrl && (
                  <HeaderItem
                    link={{
                      url: HeaderUserInfo.savedAdsUrl,
                    }}
                    visibleOnScreen="medium"
                    customClass={
                      selectedSection === 'SAVED_REAL_ESTATES'
                        ? HeaderStyles.isActive
                        : undefined
                    }
                  >
                    <PepitaIcon customClass={HeaderStyles.icon} name="heart" />
                    <span className={HeaderStyles.iconText}>
                      {trans('user_lbl_saved_ad', {
                        count: 2,
                      })}
                    </span>
                  </HeaderItem>
                )}
                {HeaderUserInfo.messagesUrl && (
                  <HeaderItem
                    link={{
                      url: HeaderUserInfo.messagesUrl,
                    }}
                    visibleOnScreen="medium"
                    customClass={
                      selectedSection === 'MESSAGING'
                        ? HeaderStyles.isActive
                        : undefined
                    }
                  >
                    <span
                      className={clsx(
                        HeaderStyles.icon,
                        'nd-notificationBadgeContainer'
                      )}
                    >
                      <PepitaIcon name="chat" />
                      {isOnClient && counters && counters.messagesNotify ? (
                        <div
                          className={clsx(
                            'nd-notificationBadge',
                            HeaderStyles.notification,
                            css[HeaderStyles.isAnimated]
                          )}
                        >
                          {counters.messagesNotify}
                        </div>
                      ) : null}
                    </span>

                    <span className={HeaderStyles.iconText}>
                      {trans('user_lbl_messages')}
                    </span>
                  </HeaderItem>
                )}
              </>
            ) : (
              <AuthButton
                label="login"
                customClass={clsx(HeaderStyles.hideOnMobile, HeaderStyles.link)}
              />
            )}
            <UserMenu
              linkConfig={linkConfig}
              mobileConfig={mobileConfig}
              customClass={clsx(HeaderStyles.item, HeaderStyles.userMenu)}
              onLoginClick={onLoginClick}
              onRegisterClick={onLoginClick}
              mailVerified={Boolean(user?.mailVerified)}
              counters={counters}
              name={user?.name}
              phoneVerified={Boolean(user?.phoneVerified)}
              surname={user?.surname}
              thumb={user?.thumb ?? undefined}
              uuid={user?.uuid}
              avatarInLightBackground={!isBranded}
            />
          </section>
        )}
      </nav>
    </header>
  );
}

export enum HeaderStyles {
  element = 'nd-navbar',
  inElement = 'in-navbar',
  row = 'nd-navbar__row',
  section = 'nd-navbar__section',
  hideOnMobile = 'in-navbar__hideOnMobile',
  brandWithSearch = 'nd-navbar__section--brandWithSearch',
  boxedRow = 'nd-navbar__row--boxed',
  logo = 'nd-navbar__logo',
  avatar = 'in-header__avatar',
  item = 'nd-navbar__item',
  btnText = 'nd-navbar__btnText',
  showOnWideScreen = 'nd-navbar__showOnWideScreen',
  hideOnWideScreen = 'nd-navbar__hideOnWideScreen',
  isAnimated = 'is-animated',
  link = 'nd-navbar__link',
  isActive = 'is-active',
  icon = 'nd-navbar__icon',
  iconText = 'nd-navbar__iconText',
  userMenu = 'nd-navbar__userMenu',
  menuBack = 'nd-navbar__item--menuBack',
  search = 'nd-navbar__item--search',
  notification = 'nd-navbar__notificationBadge',
  publishBtn = 'nd-navbar__item--publishBtn',
}
