import { useState } from 'react';

import { PepitaButton } from 'src/libs/ui/pepita-button';
import { PepitaDialog, PepitaDialogFrameType } from 'src/libs/ui/pepita-dialog';

import css from './styles.module.scss';

export function AlphaAlertButton() {
  const [showDialog, setShowDialog] = useState(false);

  return (
    <>
      <PepitaButton
        onClick={() => setShowDialog(true)}
        variant="accent"
        customClass={css['in-alphaAlertButton']}
        iconOnly
        small
        icon={{ name: 'warning' }}
      />

      {showDialog && (
        <PepitaDialog
          title="This is an alpha version"
          onClose={() => setShowDialog(false)}
          size={PepitaDialogFrameType.MEDIUM}
          content={
            <>
              <p>
                This is the <b>alpha version</b> of the porting to React of the{' '}
                <b>geographic search component.</b>
                <br />
                <br />
              </p>
              <p>
                This component may not work as you would expect, so before
                opening bug reports, try the stable version that you can find on
                the production domain.
              </p>
            </>
          }
        />
      )}
    </>
  );
}
